var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('q-dialog',{model:{value:(_vm.$store.state.modalBusqueda),callback:function ($$v) {_vm.$set(_vm.$store.state, "modalBusqueda", $$v)},expression:"$store.state.modalBusqueda"}},[_c('div',{staticClass:"col-12 row justify-center",staticStyle:{"width":"1200px"}},[_c('q-card',{staticClass:"bg-card-buscador",staticStyle:{"width":"1200px","opacity":"0.9","font-family":"'Poppins'"}},[_c('q-card-section',{staticClass:"row items-center q-pb-none"},[_c('q-space'),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"icon":"highlight_off","flat":"","round":"","dense":""},on:{"click":function($event){return _vm.cerrar()}}})],1),_c('q-card-section',{staticClass:"scroll q-pt-none",staticStyle:{"max-height":"60vh"}},[_c('div',{staticClass:"row justify-center"},[_c('div',{staticClass:"col-12 row justify-center text-center"},[_c('label',{staticStyle:{"font-size":"20px"}},[_vm._v("Encuentra todo lo relacionado con el Municipio")])]),_c('div',{staticClass:"col-11 row justify-center text-center"},[_c('q-input',{staticClass:"full-width",staticStyle:{"border-radius":"10px !important"},attrs:{"type":"search","placeholder":"¿Que necesitas encontrar?","dark":"","color":"white","rounded":"","outlined":"","clearable":"","maxlength":"80"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"search","color":"white"},on:{"click":function($event){return _vm.buscar(_vm.$store.state.textBusqueda)}}})]},proxy:true}]),model:{value:(_vm.$store.state.textBusqueda),callback:function ($$v) {_vm.$set(_vm.$store.state, "textBusqueda", $$v)},expression:"$store.state.textBusqueda"}})],1)])]),_c('q-card-section',{staticClass:"scroll q-pt-none",staticStyle:{"max-height":"60vh"}},[_c('q-card-section',{staticClass:"q-pt-none"},[_c('div',{staticClass:"row text-busqueda",style:('font-size: ' + _vm.fontExterno + 'px;')},[(
                  _vm.busquedaData &&
                    _vm.busquedaData.dataPaginas &&
                    _vm.busquedaData.dataPaginas.length == 0 &&
                    _vm.busquedaData.dataNoticias &&
                    _vm.busquedaData.dataNoticias.length == 0 &&
                    _vm.busquedaData.dataActividades &&
                    _vm.busquedaData.dataActividades.length == 0 &&
                    _vm.links &&
                    _vm.links.length == 0
                )?_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-white",staticStyle:{"font-size":"28px"}},[_vm._v(" No se han encontrado resultados ")])]):_vm._e()])]),(
              _vm.busquedaData &&
                _vm.busquedaData.dataNoticias &&
                _vm.busquedaData.dataNoticias.length > 0
            )?_c('q-card-section',{staticClass:"q-pt-none"},[_c('div',{staticClass:"row text-busqueda",style:('font-size: ' + _vm.fontExterno + 'px;')},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-white",style:('font-size: ' + _vm.fontTitulo + 'px;')},[_vm._v(" Noticias ")])]),_vm._l((_vm.busquedaData.dataNoticias),function(noticia,index){return _c('div',{key:index,staticClass:"row col-12 q-mt-md"},[(index > 0)?_c('q-separator',{staticClass:"q-mb-md",staticStyle:{"z-index":"2"},attrs:{"dark":"","size":"2px"}}):_vm._e(),_c('div',{staticClass:"col-12 text-bold cursor-pointer",on:{"click":function($event){return _vm.routerSearch('Publicacion', noticia.nombre_web)}}},[_vm._v(" "+_vm._s(noticia.titulo)+" ")]),_c('div',{staticClass:"col-12 q-mt-md q-mb-md cursor-pointer",style:('font-size: ' + _vm.fontTexto + 'px; color: white !important'),on:{"click":function($event){return _vm.routerSearch('Publicacion', noticia.nombre_web)}}},[_c('div',{domProps:{"innerHTML":_vm._f("truncate")(noticia.descripcion_corta,120, '...')}})])],1)})],2)]):_vm._e(),(
              _vm.busquedaData &&
                _vm.busquedaData.dataNoticias &&
                _vm.busquedaData.dataNoticias.length > 0 &&
                _vm.busquedaData.dataActividades &&
                _vm.busquedaData.dataActividades.length > 0
            )?_c('q-separator',{attrs:{"color":"white","inset":""}}):_vm._e(),(
              _vm.busquedaData &&
                _vm.busquedaData.dataActividades &&
                _vm.busquedaData.dataActividades.length > 0
            )?_c('q-card-section',{staticClass:"q-pt-none"},[_c('div',{staticClass:"row text-busqueda",style:('font-size: ' + _vm.fontExterno + 'px;')},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-white",style:('font-size: ' + _vm.fontTitulo + 'px;')},[_vm._v(" Actividades ")])]),_vm._l((_vm.busquedaData.dataActividades),function(actividad,index){return _c('div',{key:index,staticClass:"row col-12 q-mt-md"},[(index > 0)?_c('q-separator',{staticClass:"q-mb-md",staticStyle:{"z-index":"2"},attrs:{"dark":"","size":"2px"}}):_vm._e(),_c('div',{staticClass:"col-12 text-bold cursor-pointer",on:{"click":function($event){return _vm.routerSearch('PublicacionAct', actividad.nombre_web)}}},[_vm._v(" "+_vm._s(actividad.nombre)+" ")]),_c('div',{staticClass:"col-12 q-mt-md q-mb-md cursor-pointer",style:('font-size: ' + _vm.fontTexto + 'px; color: white !important'),on:{"click":function($event){return _vm.routerSearch('PublicacionAct', actividad.nombre_web)}}},[_c('div',{domProps:{"innerHTML":_vm._f("truncate")(actividad.descripcion,120, '...')}})])],1)})],2)]):_vm._e(),(
              _vm.busquedaData &&
                _vm.links &&
                _vm.links.length > 0 &&
                _vm.busquedaData.dataActividades &&
                _vm.busquedaData.dataActividades.length > 0
            )?_c('q-separator',{attrs:{"color":"white","inset":""}}):_vm._e(),(_vm.busquedaData && _vm.links && _vm.links.length > 0)?_c('q-card-section',[_c('div',{staticClass:"row text-busqueda",style:('font-size: ' + _vm.fontExterno + 'px;')},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-white",style:('font-size: ' + _vm.fontTitulo + 'px;')},[_vm._v(" Links de Interés ")])]),_vm._l((_vm.links),function(link,index){return _c('div',{key:index,staticClass:"row col-12 q-mt-md"},[(index > 0)?_c('q-separator',{staticClass:"q-mb-md",staticStyle:{"z-index":"2"},attrs:{"dark":"","size":"2px"}}):_vm._e(),(link.tipo == 1)?_c('div',{staticClass:"col-12 text-bold cursor-pointer",on:{"click":function($event){return _vm.irPagina(link.nombre_web)}}},[_vm._v(" "+_vm._s(link.nombre)+" ")]):_vm._e(),(link.tipo == 0)?_c('div',{staticClass:"col-12 text-bold cursor-pointer",on:{"click":function($event){return _vm.toLink(link.url)}}},[_vm._v(" "+_vm._s(link.nombre)+" ")]):_vm._e()],1)})],2)]):_vm._e(),(
              _vm.busquedaData &&
                ((_vm.busquedaData.dataLinks &&
                  _vm.busquedaData.dataLinks.length > 0) ||
                  (_vm.busquedaData.dataNoticias &&
                    _vm.busquedaData.dataNoticias.length > 0) ||
                  (_vm.busquedaData.dataActividades &&
                    _vm.busquedaData.dataActividades.length > 0))
            )?_c('q-separator',{attrs:{"color":"white","inset":""}}):_vm._e()],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }